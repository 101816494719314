.product-slider-wrapper {
  position: relative;
}

.slick-arrow {
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.slick-arrow:hover {
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.slick-prev {
  left: -20px;
}

.slick-next {
  right: -20px;
}

.icon-arrow-left::before {
  content: "<";
}

.icon-arrow-right::before {
  content: ">";
}

/* Optional: Adjust responsiveness if needed */
@media (max-width: 768px) {
  .slick-arrow {
    display: none; /* Hide arrows on smaller screens if needed */
  }
}
.footer_widgets,
.newsletter_section,
.newsletter_subscribe form input {
  background-color: #f0f0f0;
}
.footer_widgets {
  padding: 40px 0 10px;
}
.newsletter_padding {
  padding-top: 40px;
}
.footer_social {
  padding: 50px 0 50px;
}

/* #headerspace {
  margin-top: 130px;
} */

.product_thumb {
  padding: 10px;
}
/* -------------------------------------testmonial--------------------------------------------------------- */
.testmonial {
  /* background-color: #f0f0f0; */
  background: linear-gradient(-45deg, #ffe2d3, #a6dee2, #cfc2fc);
  margin-bottom: 50px;
  width: 100%;
}

.testmonial .testmonial-text {
  padding: 50px;
  text-align: center;
}

.testmonial .swiper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.testmonial .swiper-slide p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.testmonial .swiper-slide h5 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 0px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}

/* -------------------------------------notification--------------------------------------------------------- */
.notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 300px;
  z-index: 1000;
}

.notification img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification p {
  margin: 0;
  font-size: 1rem;
  flex: 1;
}

.close-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  margin-left: 10px;
}

/* -------------------------------------notification--------------------------------------------------------- */
.accordion {
  padding: 40px;
  max-width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: linear-gradient(-45deg, #ffe2d3, #a6dee2, #cfc2fc);
  margin-bottom: 50px;
}
/* 
.accordion {
  padding: 40px;
  max-width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 50px;
  background: linear-gradient(
    135deg,
    #f0f0f0 25%,
    #b0aaa0 25%,
    #b0aaa0 50%,
    #f0f0f0 50%,
    #f0f0f0 75%,
    #b0aaa0 75%
  );
  background-size: 40px 40px; 
} */

.accordion h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.accordion p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  background: linear-gradient(-45deg, #ffe2d3, #a6dee2, #cfc2fc);
  border: none;
  padding: 15px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.accordion-header:hover {
  background: #eee;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 15px;
}

.accordion-content.open {
  max-height: 200px; /* Adjust based on content height */
  padding: 15px;
}
.accordion-header h3 {
  margin: 0;
  font-size: 20px !important;
  color: #555;
  line-height: 1.6;
}
.accordion-content p {
  margin: 0;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .accordion {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .accordion-header h3 {
    margin: 0;
    font-size: 12px !important;
    color: #555;
    line-height: 1.6;
  }
}
.accordion-item {
  background-color: none;
  border: none;
}
/* -------------------------------------minicart--------------------------------------------------------- */

.minicart-header {
  position: fixed;
  top: 35px; /* Adjust as needed to position below the header */
  right: 5px; /* Adjust to position the minicart from the right edge */
  width: 400px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
  z-index: 1000; /* High z-index to overlay above other elements */
  display: none;
  height: 95vh; /* Full viewport height minus some space */
  overflow: hidden; /* Ensure the content doesn't overflow */
}

/* Show the minicart when it has the class 'show' */
.minicart-header.show {
  display: block;
}

.minicart-content {
  max-height: 80vh; /* Set the maximum height for scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Optional: Add some padding for scrollbar visibility */
}

.minicart-headermain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.minicart-headermaintext h4 {
  font-size: 30px;
}

.minicart-headermainclose {
  font-size: 30px;
}

.minicart-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.minicart-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.minicart-inner img {
  height: 100px;
}

.minicart-inner p {
  margin-top: 0;
  margin-bottom: 10px;
}

/* Scrollbar styles for minicart-content */
.minicart-content::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.minicart-content::-webkit-scrollbar-thumb {
  background-color: blue; /* Blue color for the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.minicart-content::-webkit-scrollbar-thumb:hover {
  background-color: darkblue; /* Darker blue on hover */
}

.minicart-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light background for the track */
  border-radius: 4px; /* Rounded corners */
}

/* Scrollbar styles for Firefox */
.minicart-content {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #bcb7bf #f1f1f1; /* Thumb color and track background */
}

/* Optional for other browsers */
.minicart-content {
  scroll-behavior: smooth; /* Smooth scrolling for better user experience */
}

/* -------------------------------------header_sticky--------------------------------------------------------- */

.header_section {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}

.sticky {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
.slider_section {
  padding-top: 0px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider {
    min-height: 600px;
  }
}
/* -------------------------------------mobile_nav--------------------------------------------------------- */

.mobile_nav {
  display: none;
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffff;
  z-index: 999;
  padding-top: 30px;
}

.mobile_nav.open {
  display: block;
}

.mobile_nav ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.mobile_nav ul li {
  margin: 20px 0;
}

.mobile_nav ul li a {
  color: black;
  text-decoration: none;
  font-size: 18px;
}

/* -------------------------------------AuthForm--------------------------------------------------------- */

.AuthForm-main {
  height: 80vh;
  background: #f6f5f7;
  display: grid;
  place-content: center;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
:root {
  --linear-grad: linear-gradient(to right, #c03535, #ff4b4b);
  --grad-clr1: #c03535;
  --grad-clr2: #ff4b4b;
}
.AuthForm-main-otp-container {
}
.AuthForm-main-AuthForm-container {
  position: relative;
  width: 850px;
  height: 500px;
  background-color: #fff;
  box-shadow: 25px 30px 55px #5557;
  border-radius: 13px;
  overflow: hidden;
}

.AuthForm-main-form-container {
  position: absolute;
  width: 60%;
  height: 100%;
  padding: 0px 40px;
  transition: all 0.6s ease-in-out;
}

.AuthForm-main-sign-up-container {
  opacity: 0;
  z-index: 1;
}

.AuthForm-main-sign-in-container {
  z-index: 2;
}
.AuthForm-main form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}

.AuthForm-main h1 {
  color: #333;
  font-size: 30px;
}

.AuthForm-main-social-container {
  margin: 20px 0px;
}

.AuthForm-main-social-container a {
  border: 1px solid #ddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  height: 40px;
  width: 40px;
}

.AuthForm-main span {
  font-size: 12px;
}

.AuthForm-main-infield {
  position: relative;
  margin: 8px 0px;
  width: 100%;
}

.AuthForm-main input {
  width: 100%;
  padding: 12px 15px;
  background-color: #f3f3f3;
  border: none;
  outline: none;
}

.AuthForm-main label {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 0%;
  height: 2px;
  background: linear-gradient(to right, #c03535, #ff4b4b);
  transition: width 0.3s ease;
}

.AuthForm-main input:focus ~ .AuthForm-main label {
  width: 100%;
}

.AuthForm-main a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0px;
}

.AuthForm-main a.AuthForm-main-forgot {
  padding-bottom: 3px;
  border-bottom: 2px solid #eee;
}

.AuthForm-main-form-container button {
  border-radius: 20px;
  border: 1px solid #c03535;
  background: #ff4b4b;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.AuthForm-main-form-container button {
  margin-top: 17px;
  transition: 80ms ease-in;
}

.AuthForm-main-form-container button:hover {
  background: #fff;
  color: #c03535;
}

.AuthForm-main-overlay-container {
  position: absolute;
  top: 0;
  left: 60%;
  width: 40%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 9;
}

.AuthForm-main-overlay-button {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translateX(-50%);
  width: 143.67px;
  height: 40px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 20px;
}

.AuthForm-main-overlay {
  position: relative;
  background: linear-gradient(to right, #c03535, #ff4b4b);
  color: #fff;
  left: -150%;
  height: 100%;
  width: 250%;
  transition: transform 0.6s ease-in-out;
}

.AuthForm-main-overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 40px;
  text-align: center;
  height: 100%;
  width: 340px;
  transition: 0.6s ease-in-out;
}

.AuthForm-main-overlay-left {
  right: 60%;
  transform: translateX(-12%);
}

.AuthForm-main-overlay-right {
  right: 0;
  transform: translateX(0%);
}

.AuthForm-main-overlay-panel h1 {
  color: #fff;
}

.AuthForm-main p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 25px 0px 35px;
}

.AuthForm-main-overlay-panel button {
  border: none;
  background-color: transparent;
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay-container {
  transform: translateX(-150%);
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay {
  transform: translateX(50%);
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay-left {
  transform: translateX(25%);
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay-right {
  transform: translateX(35%);
}

.AuthForm-main-right-panel-active .AuthForm-main-sign-in-container {
  transform: translateX(20%);
  opacity: 0;
}

.AuthForm-main-right-panel-active .AuthForm-main-sign-up-container {
  transform: translateX(66.7%);
  opacity: 1;
  z-index: 5;
  animation: AuthFormshow 0.6s;
}
.AuthForm-main-right-panel-active .AuthForm-main-otp-container {
  transform: translateX(66.7%);
  opacity: 1;
  z-index: 5;
  animation: AuthFormshow 0.6s;
}
.AuthForm-main-sign-in-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes AuthFormshow {
  0%,
  50% {
    opacity: 0;
    z-index: 1;
  }
  50.1%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.AuthForm-main-btnScaled {
  animation: AuthFormscaleBtn 0.6s;
}

@keyframes AuthFormscaleBtn {
  0% {
    width: 143.67px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 143.67px;
  }
}

.AuthForm-main-square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

.AuthForm-main-big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #c03535, #ff4b4b);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.AuthForm-main-inner-circle {
  position: absolute;
  width: 72%;
  height: 72%;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.AuthForm-main-infield span {
  position: absolute;
  top: 10px;
  right: 20px;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .AuthForm-main {
    height: 80vh;
    background: #f6f5f7;
    display: grid;
    place-content: center;
    font-family: "Poppins", sans-serif;
    overflow: hidden;
  }
  .AuthForm-main-AuthForm-container {
    position: relative;
    width: 350px;
    height: 500px;
    background-color: #fff;
    box-shadow: 25px 30px 55px #5557;
    border-radius: 13px;
    overflow: hidden;
  }
  .AuthForm-main-form-container {
    position: absolute;
    width: -1%;
    height: 100%;
    padding: 0px 0px;
    transition: all 0.6s ease-in-out;
  }
  .AuthForm-main-overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 0px;
    text-align: center;
    height: 100%;
    width: 130px;
    transition: 0.6s ease-in-out;
  }
  .AuthForm-main form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
  }
  .AuthForm-main-overlay-button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 340px;
    transform: translateX(-50%);
    width: 85px;
    height: 40px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 20px;
  }
  .AuthForm-main h1 {
    color: #333;
    font-size: 22px;
  }
}

/* -------------------------------------contact--------------------------------------------------------- */

.contact-googlemap {
  height: 400px;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

.contact-container {
  position: relative;
  width: 100%;
  min-height: 80vh;
  padding: 2rem;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-contact-form {
  background-color: #ff4b4b;
  position: relative;
}

.contact-circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #c03535;);
  position: absolute;
}

.contact-circle.contact-one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.contact-circle.contact-two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #c03535;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contact-container form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.contact-title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contact-input-container {
  position: relative;
  margin: 1rem 0;
}

.contact-input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.contact-input {
  padding: 0.8rem 1.2rem;
  min-height: 90px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.contact-input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.contact-input-container.contact-textarea label {
  top: 1rem;
  transform: translateY(0);
}

.contact-btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #c03535;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.contact-btn:hover {
  background-color: transparent;
  color: #fff;
}

.contact-input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.contact-input-container span:before,
.contact-input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #c03535;
  top: 50%;
  transform: translateY(-50%);
}

.contact-input-container span:before {
  left: 50%;
}

.contact-input-container span:after {
  right: 50%;
}

.contact-input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.contact-input-container.contact-focus span:before,
.contact-input-container.contact-focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-contact-info .contact-title {
  color: #c03535;
}

.contact-text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.contact-information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.contact-information i {
  color: #c03535;
}

.contact-icon {
  width: 28px;
  margin-right: 0.7rem;
}

.contact-social-media {
  padding: 2rem 0 0 0;
}

.contact-social-media p {
  color: #333;
}

.contact-social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.contact-social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #ff4b4b, #c03535);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.contact-social-icons a:hover {
  transform: scale(1.05);
}

.contact-contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #c03535;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.contact-big-circle {
  position: absolute;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #ff4b4b, #c03535);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.contact-big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.contact-square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .contact-form {
    grid-template-columns: 1fr;
  }

  .contact-contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .contact-square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .contact-big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .contact-text {
    margin: 1rem 0 1.5rem 0;
  }

  .contact-social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 1.5rem;
  }

  .contact-contact-info:before {
    display: none;
  }

  .contact-square,
  .contact-big-circle {
    display: none;
  }

  form,
  .contact-contact-info {
    padding: -1rem 1.6rem;
  }

  .contact-text,
  .contact-information,
  .contact-social-media p {
    font-size: 0.8rem;
  }

  .contact-title {
    font-size: 1.15rem;
  }

  .contact-social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .contact-icon {
    width: 23px;
  }

  .contact-input {
    padding: 0.45rem 1.2rem;
  }

  .contact-btn {
    padding: 0.45rem 1.2rem;
  }
}

/* -------------------------------------home page product hover function--------------------------------------------------------- */
/* .product_content {
  background: #ffffff;
  transition: ease all 0.4s;
  position: relative;
  padding: 18px 0 0;
} */

.product_content {
  background: #ffffff;
  transition: ease all 0.4s;
  position: relative;
  padding: 0px;
  margin-top: 45px;
  height: auto;
  z-index: 1;
}

@media (max-width: 768px) {
  .product_content {
    margin-top: 20px; /* Reduced margin for smaller screens */
    padding: 10px; /* Add padding for better spacing on mobile */
  }
}

/* Media Query for very small screens (like mobile phones) */
@media (max-width: 480px) {
  .product_content {
    margin-top: -25px; /* Even smaller margin on very small screens */
    padding: 15px; /* More padding for mobile screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

.product_content {
  transform: translateY(-60px);
  /* @media #{$small-mobile2} {
    transform: translateY(-25px);
  } */
}
.product_thumb img {
  transition: opacity 0.7s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
  transform: scale(1, 1);
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.product_thumb {
  position: relative;
}

.product_thumb img {
  width: 100%;
  transition: opacity 0.3s ease;
}

.product_thumb .secondary_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.product_thumb:hover .secondary_img {
  opacity: 1;
}

.product_thumb:hover .primary_img {
  opacity: 0;
}

#Categorycard .product_thumb img {
  transition: opacity 0.7s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
  transform: scale(1, 1);
  width: 100%;

  height: 350px;
  border-radius: 51%;
}
@media (max-width: 768px) {
  .product_thumb img {
    transition: opacity 0.7s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
    transform: scale(1, 1);
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  /* #Categorycard .product_thumb img {
    height: 335px;
  } */
  #Categorycard .product_thumb img {
    height: 440px;
  }
  .product_label {
    top: 45px;
    left: 8px;
  }
  .product_thumb {
    padding: 0 10px;
  }
  .product_action {
    top: 50px;
    right: 6px;
  }
  #prodct-la .product_label {
    top: 45px;
    left: 65px;
  }
  #prodct-la .product_action {
    top: 50px;
    right: 65px;
  }
}
#Categorycard .product_thumb img {
  width: 100%;
  transition: opacity 0.3s ease;
  object-fit: fill;
}
#Categorycard .product_content {
  background: transparent;
  transition: ease all 0.4s;
  position: relative;
  padding: 0px 0 0;
  margin-top: 60px;
}
/* -------------------------------------home page product image modal--------------------------------------------------------- */
.big_image_container {
  text-align: center;
  margin-bottom: 15px;
}

.big_image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border: 1px solid #ddd;
}

.small_images_container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.small_image {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: border-color 0.3s;
}

.small_image:hover {
  border-color: #000;
}

.zoom_tab_img img {
  margin: 10px;
  height: 100px;
}

/* -------------------------------------product deatl page full view modal--------------------------------------------------------- */
.productdetailimg-Modal {
  content: " ";
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 700px;
  background: transparent;
  padding: 50px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 899;
}
.productdetailimg-Modal img {
  height: 560px;
}

.productdetailimg-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.productdetailimg-close-button {
  position: absolute;
  top: 40px;
  right: 120px;
  font-size: 54px;
  background: none;
  border: none;

  height: 60px;
  cursor: pointer;

  z-index: 999;
}

.productdetailimg-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productdetailimg-modal-prev,
.productdetailimg-modal-next {
  position: absolute;
  top: 50%;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1000; /* Ensure buttons are above the image */
  transform: translateY(-50%);
}

.productdetailimg-modal-prev {
  left: 120px; /* Adjust as needed */
}

.productdetailimg-modal-next {
  right: 120px; /* Adjust as needed */
}
.productdetailimagefullview-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
}

.zoom_tab_img {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

/* .add_to_cart {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transition: ease all 0.4s;
  opacity: 0;
  transform: translateY(0);
  & a.btn-primary {
    margin-top: 0;
    font-size: 14px;
    padding: 0 28px;
    @media #{$large-mobile} {
      padding: 0 14px;
      font-size: 13px;
    }
  }
} */
/* -------------------------------------product deatl page add cart button--------------------------------------------------------- */

.detail-addcartbtn {
  z-index: 999;
  position: static;
}

/* Hide the dropdown by default */
.dropdown_Product {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Display dropdown on hover */
li:hover .dropdown_Product {
  display: block;
}

/* Dropdown item styling */
.dropdown_Product li {
  list-style-type: none;
  padding: 10px;
}

.dropdown_Product li a {
  text-decoration: none;
  color: #000;
}

.dropdown_Product li a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .productheaderalign-mobile {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .productheaderalign-mobile .dropdown_Product {
    margin-top: 30px;
  }
}

#productselectedmodal {
  max-height: 95vh; /* Set a max height for the modal */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#productselectedmodal img {
  max-width: 100%;
  height: auto; /* Change this to auto to maintain aspect ratio */
  object-fit: cover;
}

.filter__list.widget_size {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 0px;
  gap: 10px;
}
/* .productdetail-des {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}
.productdetail-des-ul {
  display: flex;
  justify-content: start;
  align-items: flex-start;
} */
.filter__list.widget_size h3 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  width: 142px;
  margin-right: 0px;
  margin-left: 0px;
}
@media screen and (max-width: 768px) {
  .filter__list.widget_size {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 0px;
    gap: 20px;
  }
  .filter__list.widget_size h3 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    width: 55px !important;
    margin-right: 20px;
    margin-left: 15px;
  }
  .variant_quantity_btn button {
    line-height: 20px;
    height: 45px;
    padding: 0 11px;
    margin-left: 10px;
  }
}

.filter__list.widget_size ul {
  list-style: outside none none;
  margin: 11px 0px;
  padding: 0;
}
.filter__list.widget_size ul label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.filter__list .widget_size ul li {
  display: inline-block;
  margin-right: 0px;
}
.banner_container img {
  height: 800px;
}
/* Add this to your CSS file */
a {
  text-decoration: none !important;
}

/* .modal {
  display: block;
} */

.filter__list {
  margin-bottom: 20px;
}

.options-list {
  list-style: none;
  padding: 0;
}

.options-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.options-list label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-list input[type="radio"] {
  margin-right: 10px;
}

.options-list span {
  display: inline-block;
}
.options-list input[type="checkbox"] {
  margin-right: 10px;
}
#searchmodal .closes {
  border: none;
  font-size: 25px;
  background: none;
}

.checkbox-container {
  height: 70px; /* Adjust height as needed */
  width: 70px; /* Adjust width as needed */
}

.custom-checkbox {
  transform: scale(1.5); /* Increase size of the checkbox */
  margin: 0; /* Remove default margin */
}

.hiddenCB label {
  padding: 4px 6px;
  line-height: 190%;
  outline-style: none;
  transition: all 0.6s;
  border: 1px solid #777676;
  border-radius: 5px;
  min-width: 40px;
}

.hiddenCB div {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.hiddenCB input[type="checkbox"],
.hiddenCB input[type="radio"] {
  display: none;
}

.hiddenCB label {
  cursor: pointer;
  color: black;
}

/* .hiddenCB input[type="checkbox"] + label:hover {
  background: #f7cb15;
} */
.hiddenCB input[type="checkbox"] + label {
  transition: background 0.3s ease; /* Smooth transition effect */
}

.hiddenCB input[type="checkbox"] + label:hover {
  background: rgba(247, 203, 21, 0.8); /* Yellow with transparency */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .hiddenCB input[type="checkbox"] + label:hover {
    background: #ffff; /* Increased transparency for mobile */
  }
}

.hiddenCB input[type="checkbox"]:checked + label {
  background: #6a7964;
  background-position: 100% 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s ease, background 1s ease, color 0.25s ease;
  color: white;
}

.hiddenCB input[type="checkbox"]:checked + label:hover {
  background: rgba(0, 128, 128, 0, 0.7);
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .main_header {
    padding: 13px 0;
  }
}
#cart-orderbox {
  width: 300px;
}
#cart-orderboxsecond {
  padding: 50px;
}
/* 
.checkbox.style-h {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-h input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-h input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-h input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  top: -3px;
}
.checkbox.style-h input:checked ~ .checkbox__body {
  background-position: 0 0;
  color: #fff;
}
.checkbox.style-h:hover input ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-h:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-h:hover .checkbox__body {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.checkbox.style-h .checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  height: 22px;
  width: 22px;
  background-color: #f7cb15;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1),
    inset -2px -2px 4px rgba(255, 255, 255, 0.4);
}
.checkbox.style-h .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 10px;
  top: -15px;
  width: 10px;
  height: 20px;
  border: solid #fff;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease, top 0.25s ease;
}
.checkbox.style-h .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  transition: font-weight 0.25s ease;
  font-weight: bold;
  color: #333;

  background: linear-gradient(
    45deg,
    #ff0070 0%,
    #f7cb15 50%,
    #f7cb15 50%,
    #f7cb15 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  padding: 15px;
  padding-left: 52px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s ease, background 1s ease, color 0.25s ease;
} */

.checkbox.style-h {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-h input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* .checkbox.style-h input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
} */
.checkbox.style-h input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  top: -3px;
}
.checkbox.style-h input:checked ~ .checkbox__body {
  background-position: 0 0;
  color: black;
}
/* .checkbox.style-h:hover input ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-h:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
} */
.checkbox.style-h:hover .checkbox__body {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.checkbox.style-h .checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  height: 22px;
  width: 22px;
  /* background-color: #f7cb15; */
  transition: background-color 0.25s ease;
  border-radius: 4px;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1),
    inset -2px -2px 4px rgba(255, 255, 255, 0.4);
  border: 1px solid black;
}
.checkbox.style-h .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 10px;
  top: -15px;
  width: 10px;
  height: 20px;
  border: solid red;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease, top 0.25s ease;
}
.checkbox.style-h .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  transition: font-weight 0.25s ease;
  font-weight: bold;
  color: #333;

  /* background: linear-gradient(
    45deg,
    #ff0070 0%,
    #f7cb15 50%,
    #f7cb15 50%,
    #f7cb15 100%
  ); */
  background-size: 200% 100%;
  background-position: 100% 0;
  padding: 15px;
  padding-left: 52px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s ease, background 1s ease, color 0.25s ease;
}
/* -------------------------image zoom-------------------- */
.product_content .product_name a {
  color: black;
}
.product_content .product_name a:hover {
  color: blue;
}

:root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --trans-dur: 0.3s;
}
.loadingbody {
  background-color: white;
  color: var(--fg);

  height: 100vh;
  display: grid;
  place-items: center;
  transition: background-color var(--trans-dur);
}
.loadingbody main {
  padding: 1.5em 0;
}
.loadingbody .ip {
  width: 16em;
  height: 8em;
}
.loadingbody.ip__track {
  stroke: hsl(var(--hue), 90%, 90%);
  transition: stroke var(--trans-dur);
}
.loadingbody .ip__worm1,
.loadingbody .ip__worm2 {
  animation: worm1 2s linear infinite;
}
.loadingbody .ip__worm2 {
  animation-name: worm2;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 5%);
    --fg: hsl(var(--hue), 90%, 95%);
  }
  .loadingbody .ip__track {
    stroke: hsl(var(--hue), 90%, 15%);
  }
}

@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }
  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
}
.paymet_img img {
  height: 150px;
}
.paymet_img {
  border-bottom: 1px solid #ddd;
}
.product_label span {
  width: 75px;
}
#sold span {
  width: 75px;
  margin-top: 30px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  background: #ff2f2f;
}
/* ------------------------track------------------------------------------ */
.copy-success {
  margin-left: 10px;
  color: green;
  font-size: 14px;
  font-weight: bold;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.checkout_form h3 {
  font-size: 18px;
  line-height: 36px;
  color: #000000;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.price_box span.old_price {
  font-size: 14px;
  color: #999999;
  text-decoration: line-through;
  margin-left: 5px;
}
/* #product-cont-height {
  height: auto;
} */

#product-cont-height {
  height: 530px;
}

@media (max-width: 768px) {
  #product-cont-height {
    height: 300px;
  }
}
.banner {
  background-image: url("./banner1.jpg"); /* Correct path */
  background-size: cover;
  background-position: center;
}
.banner1 {
  background-image: url("./banner2.png"); /* Correct path */
  background-size: cover;
  background-position: center;
}
.banner2 {
  background-image: url("./banner3.png"); /* Correct path */
  background-size: cover;
  background-position: center;
}
.banner_container-foot {
  display: flex;
  flex-direction: row;
}
.banner_container-foot img {
  height: 800px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .banner {
    background-image: url("./banner1mobile.png"); /* Mobile-specific image */
    height: 300px; /* Adjust for smaller screens */
  }
  .banner1 {
    background-image: url("./banner2mobile.png"); /* Mobile-specific image */
    height: 300px; /* Adjust for smaller screens */
  }
  .banner2 {
    background-image: url("./banner3mobile.png"); /* Mobile-specific image */
    height: 300px; /* Adjust for smaller screens */
  }
  .banner_container-foot {
    display: flex;
    flex-direction: column;
  }
  .banner_container-foot img {
    height: 500px;
    width: 100%;
  }
}
/* -----------------------------new design------------------------------------- */
/* ProductGrid.css */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 20px;
}

.grid-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0px;
  text-align: center;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.grid-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0px 0px;
}

.product-name {
  font-size: 1.2em;
  margin: 10px 0;
}

.product-price {
  font-size: 1em;
  color: #555;
}

.add-to-cart:hover {
  background-color: #0056b3;
}

/* ---------------------- */
/* ProductGrid.css */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 20px;
}

.grid-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.grid-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-thumb {
  position: relative;
  overflow: hidden; /* Ensure secondary image stays within bounds */
}

.primary-img {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}
.secondary-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease-in-out;
}

.product-thumb:hover .primary-img {
  opacity: 0; /* Fade out primary image on hover */
}

.product-thumb:hover .secondary-img {
  opacity: 1; /* Fade in secondary image on hover */
}

.product-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background: red;
  color: white;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
}

.product-label.sold-out {
  background: grey;
}

.product-action {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;

  gap: 8px;
}

.product-action ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product-content {
  padding: 0px;
  text-align: center;
}

.product-name {
  font-size: 1.2em;
  margin: 10px 0;
}

.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.current-price {
  color: #ff5722;
  font-size: 1.1em;
  font-weight: bold;
}

.old-price {
  color: #999;
  text-decoration: line-through;
  font-size: 0.9em;
}

.wishlist-icon {
  font-size: 30px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  background-color: #ffff;
}
.quick-view {
  padding: 5px;
  border-radius: 45%;
  height: 30px;
  background-color: #ffff;
}

.wishlist-icon.active {
  color: red;
}
.inr {
  font-size: 12px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px; /* Two images per row */
  }
  .product-name {
    font-size: 14px;
    margin: 5px 0;
  }
  .current-price {
    color: #ff5722;
    font-size: 14px;
    font-weight: bold;
  }

  .old-price {
    color: #999;
    text-decoration: line-through;
    font-size: 12px;
  }
  .product-label {
    position: absolute;
    top: 10px;
    left: 5px;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px; /* Two images per row */
  }
}
#scrollUp {
  background: #6a7964;
  bottom: 85px;
  color: #ffffff;
  cursor: pointer;
  display: none; /* Will be shown dynamically with JS */
  font-size: 20px;
  height: 45px;
  line-height: 39px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 45px;
  z-index: 9999;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 768px) {
  #scrollUp {
    bottom: 20px; /* Adjusted for smaller screens */
    right: 10px; /* Slightly closer to the edge for better positioning */
    font-size: 18px; /* Reduced size for smaller screens */
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 480px) {
  #scrollUp {
    bottom: 15px; /* Further adjustment for very small screens */
    right: 8px;
    font-size: 16px;
    height: 35px;
    width: 35px;
  }
}
/* Container for the collections section */
/* Container for collections */
.collections-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Set max-width to center content */
  margin: 0 auto; /* Center container horizontally */
}

/* Individual collection item */
.collection-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Center items horizontally */
  gap: 10px;
  flex-wrap: wrap; /* Wrap content for responsiveness */
}

/* Reverse layout for alternating items */
.collection-item.reverse {
  flex-direction: row-reverse;
}

/* Collection image */
.collection-image {
  width: 100%;
  max-width: 500px; /* Limit the image size */
  height: auto;

  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Content section */
.collection-content {
  max-width: 500px; /* Set max-width for content alignment */
  text-align: center; /* Center align text */
  padding: 0 20px; /* Add padding for spacing */
}

/* Collection category */
.collection-category {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #888;
  margin-bottom: 10px;
}

/* Collection title */
.collection-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

/* Description text */
.collection-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Button styles */
.collection-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.collection-button:hover {
  background-color: #444;
}

/* Responsive Design */
@media (max-width: 768px) {
  .collection-item {
    flex-direction: column; /* Stack items in a column */
    text-align: center; /* Center align text and items */
  }

  .collection-item.reverse {
    flex-direction: column; /* Same stacking for reverse */
  }

  .collection-image {
    width: 100%; /* Full width for mobile */
    margin-bottom: 20px;
  }

  .collection-content {
    width: 100%; /* Full width for mobile */
  }
}
/* Set a maximum height and enable scrolling */
.max-height-800 {
  max-height: 300px; /* Set this to your desired height */
  overflow-y: scroll; /* Enable vertical scrolling */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.max-height-800::-webkit-scrollbar {
  width: 0px; /* This hides the scrollbar */
  background: transparent; /* This makes it fully transparent */
}

/* Hide scrollbar for Firefox */
.max-height-800 {
  scrollbar-width: none; /* This hides the scrollbar in Firefox */
}
.footer_contact_list {
  margin-bottom: 0px;
}
@media only screen and (max-width: 767px) {
  .order_table_right {
    margin-top: 0;
  }
  #cart-orderboxsecond {
    padding: 0px;
  }
  .cart_product_text ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
}
@media (max-width: 480px) {
  .contact-contact-info form {
    padding: -0.3rem 1.6rem;
  }
}
.checkboxes__row {
  width: 200px;
  padding: 0px 5px;
}
/* .cart-center {
  display: flex;
  justify-content: none;
} */
.cart_page_tabel table tbody tr td:nth-child(3) {
  width: 150px;
}

.product_quantity input {
  height: 38px;
  padding: 0 5px 0 10px;
  background: none;
  border: none;
}
.cart-border {
  border: none;
  border-radius: 5px 0 0 5px;
  height: 38px;
}
.cart-borderplus {
  border: none;
  border-radius: 0px 5px 5px 0px;
  height: 38px;
}
.cart_product_quantity {
  margin-left: 0px;
}

@media only screen and (max-width: 767px) {
  .cart-center {
    display: flex;
    justify-content: center;
  }
  .cart_product_quantity {
    margin-left: 180px;
  }
  .cart_page_tabel table tbody tr td {
    padding: 4px 0;
  }
}
/* Default layout for larger screens */
.border-danger {
  border-color: red !important;
}
.input-error::placeholder {
  color: red;
}

/* /--------------------------------------new cart css--------------------------/ */

@media only screen and (min-width: 768px) {
  .cart_page_mobiletabel {
    display: none;
  }
}

/* Optionally, show it for mobile screens (below 767px) */
@media only screen and (max-width: 767px) {
  .cart_page_mobiletabel {
    display: block; /* or any other suitable display style for mobile */
  }
}
/* Hide the table on mobile */
@media only screen and (max-width: 767px) {
  .cart_page_tabel table {
    display: none; /* Hide table on mobile */
  }

  .cart_item_mobile {
    display: block; /* Show new design for mobile */
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd; /* Border around each item */
    border-radius: 5px;
  }

  .cart_item_mobile img {
    max-width: 100px;
    height: auto;
    margin-right: 15px;
  }

  .cart_item_mobile_info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    gap: 10px;
  }

  .cart_item_mobile_info h4 {
    font-size: 16px;
  }

  .cart_item_mobile_info ul li {
    font-size: 14px;
  }

  .cart_item_mobile_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart_item_mobile_actions .quantity {
    display: flex;
    align-items: center;
  }

  .cart_item_mobile_actions .quantity button {
    padding: 5px 10px;
  }

  .cart_item_mobile_actions .quantity input {
    width: 50px;
    text-align: center;
    margin: 0 5px;
  }

  .cart_item_mobile_actions button {
    font-size: 14px;
    padding: 5px 10px;
  }
  .cart_item_mobile_price {
    padding: 10px 0px;
  }

  .cart_item_mobile_remove button {
    font-size: 24px;
    color: red;
    border-radius: 50%; /* Makes the button round */
    padding: 10px 15px; /* Adjust the padding to make the button more circular */
    border: none;
    background-color: transparent; /* Makes the background transparent */
    display: inline-flex; /* Ensures proper alignment of button content */
    justify-content: center;
    align-items: center;
  }
}
/* AuthForm.css */
.auth-container-infi {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.auth-container-infi .auth-form {
  display: flex;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
}

.auth-container-infi .login-section,
.register-section {
  width: 50%;
  padding: 20px 30px;
  box-sizing: border-box;
}

.auth-container-infi h2 {
  margin-bottom: 20px;
  color: #333333;
}

.auth-container-infi label {
  display: block;
  margin-bottom: 5px;
  color: #555555;
  font-weight: bold;
}

.auth-container-infi input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.auth-container-infi input:focus {
  outline: none;
  border-color: #6c63ff;
}

.auth-container-infi button {
  width: 100%;
  padding: 10px;
  color: #ffffff;
  background-color: #6c63ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 15px;
}
#register-username .error-input {
  border: 2px solid red;
}

.auth-container-infi button:hover {
  background-color: #574fd8;
}

.auth-container-infi .google-login {
  background-color: #db4437;
}

.auth-container-infi .google-login:hover {
  background-color: #c23321;
}

.auth-container-infi .social-login p {
  text-align: center;
  margin-bottom: 10px;
}

.auth-container-infi .login-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-container-infi .forgot-password {
  display: block;
  text-align: right;
  color: #6c63ff;
  text-decoration: none;
  font-size: 14px;
}

.auth-container-infi .forgot-password:hover {
  text-decoration: underline;
}

.auth-container-infi .privacy-policy {
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
  line-height: 1.5;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .auth-container-infi .auth-form {
    flex-direction: column;
    width: 100%;
  }

  .auth-container-infi .login-section,
  .register-section {
    width: 100%;
    padding: 15px;
  }

  .auth-container-infi h2 {
    text-align: center;
  }

  .auth-container-infi .social-login p {
    font-size: 14px;
  }

  .auth-container-infi .login-actions {
    flex-direction: column;
    gap: 10px;
  }
}

/* Modal Styling */
.ModalOverlay-infi {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ModalContent-infi {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ModalHeader-infi h2 {
  margin-bottom: 15px;
  color: #333;
}

.ModalDescription-infi {
  margin-bottom: 20px;
  color: #555;
  font-size: 16px;
}

.ModalInput-infi {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.ModalButton-infi {
  background-color: #28a745;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
}

.ModalButton-infi:hover {
  background-color: #218838;
}

.CloseButton-infi {
  background-color: #dc3545;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
}

.CloseButton-infi:hover {
  background-color: #c82333;
}
.password-input-container {
  position: relative;
  margin-bottom: 15px;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-togglelogin {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
}
.user-main {
  background-color: #f5f5f5;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-card {
  background-color: #f7dfa3;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  text-align: center;
  margin: 20px auto;
}

.user-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-card-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-card h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.user-card-body {
  margin-top: 20px;
}

.logout-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-btn:hover {
  background-color: #0056b3;
}
.header_login span {
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  text-transform: uppercase;
  color: #d2a534;
  text-decoration: underline;
  font-weight: 500;
}
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
  background-color: #d2a534;
  color: white;
  font-size: 20px;
  padding: 10px 0;
}

.marquee span {
  display: inline-block;
  padding-left: 100%; /* Start off-screen */
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Move to the left */
  }
}
@media only screen and (max-width: 767px) {
  .header_top {
    padding: 0px 0;
  }
  .header_social {
    justify-content: center;
    margin: 10px 0 0 0;
  }
}
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
  background-color: #d2a534;
  color: white;
  font-size: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: center; /* Center the marquee text */
  align-items: center; /* Vertically center the text */
}

.marquee span {
  display: inline-block;

  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Move to the left */
  }
}
.timer {
  font-size: 18px;
  font-weight: bold;
  color: #ff4500; /* Change to any color you prefer */
}
@media only screen and (max-width: 767px) {
  .header_social ul li a {
    color: #ffff;
    margin-right: 16px;
  }
}
/* 
.checkout_form_input .form-control {
  border: 1px solid #cccccc;
  background: none;
  height: 54px;
  width: 100%;
  padding: 0 20px;
  color: #495057;
  border-radius: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: all 0.3s ease;
}

.checkout_form_input .form-control option {
  padding: 10px;
  background-color: #fff;
  color: #495057;
  font-size: 14px;
  cursor: pointer;
  overflow-y: auto;
  max-height: 200px;
}

.checkout_form_input .form-control option:hover {
  background-color: #f1f1f1;
} */

.suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background: white;
  position: absolute;
  width: auto;
  z-index: 1000;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background: #f0f0f0;
}

.no-suggestions {
  padding: 10px;
  color: #999;
}
